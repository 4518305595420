import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import router from '@/router';

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

export const event_obj = mitt();
export const btn_list = [
  {
    name: '签署',
    clazz: 'primary',
    cb: (data: any) => {
      router.push({ path: '/cc_adviser/adviser_sign/' + data.id });
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [7, 7],
  updateCol: [],
};

export const head_btn_list = [];
