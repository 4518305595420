import {
  create_contract,
  delete_contract,
  query_contract_list,
  recover_contract,
  update_contract,
  upload_contract_wechat_appendix,
} from '@/api/cerp-model';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ADVISER_KEY, STORAGE_CURRENT_COMPANY, STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { btn_list, event_obj, head_btn_list } from '@/components/page-model/cerp/cerp-contract-config';
import { baseColumns, newSlotList } from '@/components/page-model/cerp/cerp-contract-colums.ts';
import { createVNode, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { message, Modal, notification } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import axios from 'axios';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const adviser = ls.get(STORAGE_CURRENT_ADVISER_KEY);
const current_company = ls.get(STORAGE_CURRENT_COMPANY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    search_text: '',
    is_active: true,
    adviser_id: adviser.adviser_id,
    contact_person_id: null,
    management_fee_formula_id: null,
    cc_email_id: null,
    invoice_email_template_id: null,
    payslip_email_template_id: null,
    contract_template_id: null,
    cc_company_id: current_company?.id,
    is_current: null,
    is_bank_charge_payment: null,
    insurance_payment_flag: null,
    recuitment_company_person_id: null,
    recuitment_company_id: null,
    work_location_id: null,
    rate_type: null,
    end_client_id: null,
    currency_id: null,
    remark: null,
    management_fee_payment_flag: null,
  }),
};
const search_options = {};

const edit_model_config: SearchConfig = {
  settings: {},
  fields: [],
  rules: {},
  model: reactive({}),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {},
  fields: [],
  rules: {},
  model: reactive({}),
};
const update_model_config_options = {};

const titleList = {
  title: t('router.title.cerp-contract'),
  addModalTitle: '新合同',
  updateTitle: '修改合同',
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: false, // 是否有【删除】按钮
  is_recover: false, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    contract_id: 'id',
  },
  delete: {
    contract_id: 'id',
  },
  recover: {
    contract_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    contract_id: 'id',
  },
};
newSlotList.push(
  {
    type: 'color',
    slotName: 'email_record__status',
    cb: (record: any) => {
      if (record.email_record__status == 0 || record.email_record__status == 1) {
        return 'font-weight: bolder';
      } else if (record.email_record__status == 2) {
        return 'color: #FF0000;font-weight: bolder';
      }
    },
    content: (record: any) => {
      if (record.email_record__status == 1) {
        return '发送成功';
      } else if (record.email_record__status == 2) {
        return '发送失败';
      } else if (record.email_record__status == 0) {
        return '已发送';
      }
    },
  },
  {
    type: 'color',
    slotName: 'qiyuesuo_status',
    cb: (record: any) => {
      if (record.status == 1 || record.status == 2 || record.status == 3) {
        return 'font-weight: bolder';
      } else if (record.status == 4 || record.status == 5) {
        return 'color: #FF0000;font-weight: bolder';
      }
    },
    content: (record: any) => {
      if (record.status == 1) {
        return '草稿';
      } else if (record.status == 2) {
        return '发起合同';
      } else if (record.status == 3) {
        return '签署完成';
      } else if (record.status == 4) {
        return '签署拒绝';
      } else if (record.status == 5) {
        return '签署失败';
      }
    },
  },
  {
    type: 'custom',
    slotName: 'rate_type',
    field_0: '日薪',
    field_1: '月薪',
    field_2: '年薪',
    field_3: '时薪',
    field_4: '周薪',
  },
  {
    type: 'custom',
    slotName: 'is_bank_charge_payment',
    field_true: '顾问支付',
    field_false: '顾问公司支付',
  },
  {
    type: 'custom1',
    slotName: 'insurance_payment_flag',
    content: (record: any) => {
      if (record.insurance_payment_flag == -1) {
        return '无';
      } else if (record.insurance_payment_flag == 0) {
        return '顾问支付';
      } else if (record.insurance_payment_flag == 1) {
        return '顾问公司支付';
      } else {
        return '';
      }
    },
  },
  {
    type: 'custom',
    slotName: 'management_fee_payment_flag',
    field_0: '顾问支付',
    field_1: '顾问公司支付',
  },
  {
    type: 'clickList',
    slotName: 'appendix_json',
    cb: (record: any) => {
      if (record && record.file_url) {
        // const url: string = 'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + record.file_url;
        // window.open(url, '_blank');
        axios.get('/' + record.file_url,{
          responseType: 'blob' }).then((res: any) => {
          var blob = res.data;
          // FileReader主要用于将文件内容读入内存
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          // onload当读取操作成功完成时调用
          reader.onload = (e: any) => {
            var a = document.createElement('a');
            // 获取文件名fileName
            var fileName = res.config["url"].split("/");
            fileName = fileName[fileName.length - 1];
            // fileName = fileName.replace(/"/g, "");
            a.download = fileName;
            a.href = e.target.result;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        })
      } else {
        notification.error({
          message: '提示',
          description: '无对应附件',
        });
      }
    },
    del: (record: any, text: any, file: any) => {
      if (file.file_id) {
        Modal.confirm({
          title: '提示',
          content: '是否删除[' + file.name + ']',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            return new Promise(resolve => {
              const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
              let ids: any = [];
              text.forEach((itme: any) => {
                if (itme.file_id != file.file_id) {
                  ids.push(itme.file_id);
                }
              });
              if (ids.length == 0) {
                ids = 0;
              }
              update_contract({
                contract_id: record.id,
                appendix_ids: ids,
                org_id: current_org?.id,
              })
                .then(() => {
                  message.success('删除成功');
                  event_obj.emit('refresh');
                })
                .finally(() => {
                  resolve(null);
                });
            });
          },
          onCancel() {},
        });
      }
    },
  },
  {
    type: 'clickList',
    slotName: 'wechat_appendix_json_list',
    cb: (record: any) => {
      if (record && record.file_url) {
        // const url: string = 'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + record.file_url;
        // window.open(url, '_blank');
        axios.get('/' + record.file_url,{
          responseType: 'blob' }).then((res: any) => {
          var blob = res.data;
          // FileReader主要用于将文件内容读入内存
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          // onload当读取操作成功完成时调用
          reader.onload = (e: any) => {
            var a = document.createElement('a');
            // 获取文件名fileName
            var fileName = res.config["url"].split("/");
            fileName = fileName[fileName.length - 1];
            // fileName = fileName.replace(/"/g, "");
            a.download = fileName;
            a.href = e.target.result;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        })
      } else {
        notification.error({
          message: '提示',
          description: '无对应附件',
        });
      }
    },
    del: (record: any, text: any, file: any) => {
      if (file.file_id) {
        Modal.confirm({
          title: '提示',
          content: '是否删除[' + file.name + ']',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            return new Promise(resolve => {
              const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
              let ids: any = [];
              text.forEach((itme: any) => {
                if (itme.file_id != file.file_id) {
                  ids.push(itme.file_id);
                }
              });
              if (ids.length == 0) {
                ids = 0;
              }
              upload_contract_wechat_appendix({
                contract_id: record.id,
                appendix_ids: ids,
                org_id: current_org?.id,
              })
                .then(() => {
                  message.success('删除成功');
                  event_obj.emit('refresh');
                })
                .finally(() => {
                  resolve(null);
                });
            });
          },
          onCancel() {},
        });
      }
    },
  },
)
export default () => {
  const route = useRoute();
  search_config.model = reactive({ ...search_config.model });
  search_config.model.search_text = route.query.q;
  search_config.model.cc_company_id = route.query.cc_company_id ? route.query.cc_company_id : current_company?.id;
  search_config.model.ids = Number(route.query.ids);
  // onMounted(() => {
  //   watch(
  //     () => route.query,
  //     () => {
  //       if (route.path == '/adviser-manage/cerp-contract' && route.query.q) {
  //         // search_config.model.search_text = route.query.q;
  //         event_obj.emit('refresh_search', { search_text: route.query.q });
  //       }
  //     },
  //   );
  // });
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_contract_list,
    addData: create_contract,
    updateData: update_contract,
    removeData: delete_contract,
    recoverData: recover_contract,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
