import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {view_contract_pdf} from "@/api/cerp-model";
import {notification} from "ant-design-vue";
import {useRouter} from "vue-router";
const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const router = useRouter();
export const event_obj = mitt();
export const btn_list = [
  {
    name: '入职流程',
    clazz: 'primary',
    is_show: (record: any) => {
      return !record.qiyuesuo_status;
    },
    cb: (data: any) => {
      router.push({ path: '/cc_adviser/Induction_process' });
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [9, 9, 9, 10],
  updateCol: [7, 7, 7, 7],
};

export const head_btn_list: btnTd[] = [];
