
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  watch,
  computed,
  createVNode,
  onActivated,
  onDeactivated,
} from 'vue';
import {
  PlusOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  ReloadOutlined,
  RedoOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  ForwardFilled,
} from '@ant-design/icons-vue';
import { TableColumn } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import FormModal from '@/components/form-modal/form-modal.vue';
import { useStore } from 'vuex';
import { message, Modal } from 'ant-design-vue';
import { useForm } from 'ant-design-vue/es/form';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import getPageConfig from '@/components/page-model/index';
import { useRoute } from 'vue-router';
import { getRangePicker, getObjList } from '@/utils/function';
import {debounce} from "lodash-es";
import {useI18n} from "vue-i18n";
import {get_adviser_sign_contract} from "@/api/cerp-model";
import {GET_NO_SIGN_CONTRACT} from "@/store/modules/user/actions";
import router from "@/router";

export default defineComponent({
  name: 'PageModel',
  props: {
    modalType: {
      type: String,
    },
    activeKey: {
      type: String,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const confirmLoading = ref<boolean>(false);
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const adviser = computed(() => store.getters[`user/adviser`]);
    let formModalUpdate = false; // 判断显示创建、修改
    const searchEle:any = ref();
    const tableHeight = reactive({ y: window.innerHeight - 300 });
    const onresize:any = debounce(()=>{
      if(searchEle.value){
        tableHeight.y = window.innerHeight - searchEle.value.offsetHeight - 210;
      }else{
        tableHeight.y = window.innerHeight - 210;
      }
    }, 200);
    onMounted(() => {
      window.onresize = onresize;
      onresize();
    });

    const route = useRoute();
    const path_arr = route.path.split('/');
    const field = {
      modalType: path_arr[path_arr.length - 1],
      column_flag: 'column_' + path_arr[path_arr.length - 1],
    };
    if (props.modalType) {
      field.modalType = props.modalType as string;
      field.column_flag = 'column_' + props.modalType;
    }
    onMounted(() => {});

    watch(
      () => props.activeKey,
      () => {
        if (props.activeKey == props.modalType) {
          reload();
        }
      },
    );
    const summaryColumns = computed(() => {
      return dynamicColumns.value
        .filter((item: any) => {
          if (item.checked === true) {
            return true;
          } else if (item.checked === undefined) {
            return true;
          }
          return false;
        })
        .map((item: any, index: number) => {
          const obj = { index: index, columnKey:item.dataIndex, summary: item.summary, color: item.color, content:item.content };
          return obj;
        });
    });
    const summaryColumnsShow = computed(() => {
      return dynamicColumns.value.filter(item => item.summary).length > 0;
    });

    const {
      searchConfig,
      searchConfigOptions,
      formModalConfig,
      formModalConfigOptions,
      updateFormModalConfig,
      updateFormModalConfigOptions,
      queryData,
      processData,
      processColumn,
      addData,
      updateData,
      removeData,
      recoverData,
      baseColumns,
      column_list_change,
      titleList,
      api_fields,
      btn_list,
      head_btn_list,
      event_obj,
      init_request,
      selectedRowKeys,
      rowSelection,
      expandedRowRender,
      expandedRowRenderComponent,
      headerComponent,
      rowKey,
      pageSize,
      customCell,
      customRow,
      customHeaderCell,
    } = getPageConfig(field.modalType);

    const column_normal_btn_list: any = computed(() => {
      return btn_list?.filter((item: any) => item.type == undefined || item.type == 'btn');
    });
    const column_dropdown_btn_dict: any = computed(() => {
      const l = btn_list?.filter((item: any) => item.type == 'dropdown');
      if (l && l.length > 0) {
        return { dropdown_btn: l[0], btn_list: l.slice(1) };
      } else {
        return null;
      }
    });

    if (searchConfig.fields) {
      for (let i = 0; i < searchConfig.fields.length; i++) {
        // 预先填充 placeholder
        let filed_dict = searchConfig.fields[i];
        if (!filed_dict.placeholder) {
          let pre_str = filed_dict.type == 'input' ? t('page.pagemodel4.Please_fill_in') : t('page.pagemodel4.Please_select');
          filed_dict.placeholder = pre_str + filed_dict.label;
        }

        // 预先填充 校验message
        let search_rule_dict = searchConfig.rules[filed_dict.name];
        if (search_rule_dict) {
          if (!search_rule_dict.message) {
            search_rule_dict.message = filed_dict.placeholder;
          }
        }
      }
    }

    if (formModalConfig.fields) {
      for (let i = 0; i < formModalConfig.fields.length; i++) {
        let filed_dict = formModalConfig.fields[i];
        if (!filed_dict.placeholder) {
          let pre_str = filed_dict.type == 'input' ? t('page.pagemodel4.Please_fill_in') : t('page.pagemodel4.Please_select');
          filed_dict.placeholder = pre_str + filed_dict.label;
        }
      }
    }

    const event_obj_refresh_search = (query_data: any) => {
      Object.assign(searchConfig.model, query_data);
      search();
    };
    const event_obj_reload = () => {
      reload();
    };
    event_obj?.on('refresh_search', event_obj_refresh_search);
    event_obj?.on('refresh', event_obj_reload);
    event_obj?.on('reset_and_reload', event_obj_reload);
    onActivated(() => {
      reload();
      event_obj?.off('refresh_search', event_obj_refresh_search);
      event_obj?.off('refresh', event_obj_reload);
      event_obj?.off('reset_and_reload', event_obj_reload);

      event_obj?.on('refresh_search', event_obj_refresh_search);
      event_obj?.on('refresh', event_obj_reload);
      event_obj?.on('reset_and_reload', event_obj_reload);
    });
    onDeactivated(() => {
      event_obj?.off('refresh_search', event_obj_refresh_search);
      event_obj?.off('refresh', event_obj_reload);
      event_obj?.off('reset_and_reload', event_obj_reload);
    });

    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
      configState,
      update_column_org_config,
      update_column_data,
      resizeColumn,
      switch_column_config,
      columnAction,
      column_change,
      replace_column,
    } = useTableDynamicColumns(
      baseColumns as TableColumn[],
      {
        checkAll: false,
        needRowIndex: false,
      },
      store,
      field.column_flag,
      column_list_change,
    );

    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();
    const { resetFields, validateInfos } = useForm(searchConfig.model, searchConfig.rules);
    Object.keys(route.query).map((key:string)=>{
      if(searchConfig.fields.find((item:any)=>item.name==key)){
        searchConfig.model[key] = route.query[key];
      }
    });

    const {
      stripe,
      reload,
      setPageInfo,
      context: state,
    } = useFetchData(
      queryData,
      {
        current: 1,
        pageSize: pageSize ? pageSize : 50,
        tableSize: 'small', // 'default' | 'middle' | 'small'
        stripe: true,
        init_request: !(init_request === false),
        requestParams: {
          ...searchConfig.model,
          ...getRangePicker(api_fields.rangepicker, searchConfig.model),
        },
      },
      undefined,
      processData,
      processColumn,
      replace_column,
    );
    let sortFieldList:Array<string> = [];
    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
      let filter_map: any = {};
      let sort_fields: any = [];
      for (let key in filters) {
        filter_map[key] = filters[key].map((x: any) => x.toString()).join(',');
      }
      if(sorter instanceof Array){
        sortFieldList = sortFieldList.filter((field:string)=>{
          return sorter.find((item:any)=>field==item.column.dataIndex)!=undefined;
        });
        sorter.map((item:any)=>{
          if(!sortFieldList.includes(item.column.dataIndex)){
            sortFieldList.push(item.column.dataIndex)
          }
        });
        sort_fields = sortFieldList.map((field:string)=>{
          if(sorter.find((item:any)=>field==item.column.dataIndex).order=='descend'){
            return `-${field}`;
          }else{
            return field;
          }
        });
      }else if(sorter != undefined && sorter.column != undefined){
        sortFieldList.length = 0;
        sortFieldList.push(sorter.column.dataIndex);

        if(sorter.order == 'ascend'){
          sort_fields.push(sorter.column.dataIndex);
        }
        if(sorter.order == 'descend'){
          sort_fields.push('-' + sorter.column.dataIndex);
        }
      }else{
        sortFieldList.length = 0;
      }

      for (let key in filters) {
        filter_map[key] = filters[key].map((x: any) => x.toString()).join(',');
      }
      setPageInfo({
        current: pagination?.current ? pagination?.current : 1,
        pageSize: pagination?.pageSize ? pagination?.pageSize : 50,
        ...searchConfig.model,
        ...filter_map,
        sort_fields: sort_fields,
      });
      reload();

    };

    const clearFilterAndOrder = (column:any)=>{
      if(column.filteredValue!=undefined){
        column.filteredValue.length = 0;
      }
      if(column.sortOrder!=undefined){
        column.sortOrder = false;
      }

      if(column.children!=undefined){
        column.children.forEach((item:any)=>clearFilterAndOrder(item));
      }
    };

    const search = () => {
      sortFieldList.length = 0;
      dynamicColumns.value.forEach((column:any)=>clearFilterAndOrder(column));
      setPageInfo({
        current: 1,
        ...searchConfig.model,
        ...getRangePicker(api_fields.rangepicker, searchConfig.model),
      });
      reload();
      onresize();
    };
    const handleReSize = ()=>{
      onresize();
    };
    // edit
    const editModalAdd = reactive({
      visible: false,
      title: '',
      aim_org_id: '',
      form: formModalConfig,
      options: formModalConfigOptions,
    });
    const editModalUpdate = reactive({
      visible: false,
      title: '',
      aim_org_id: '',
      form: updateFormModalConfig,
      options: updateFormModalConfigOptions,
    });
    let currentRecord = reactive({ name: '' });
    const handleOpenEdit = (record: any) => {
      formModalUpdate = true;
      editModalUpdate.visible = true;
      editModalUpdate.title = titleList.updateTitle;
      editModalUpdate.form.model = {
        ...updateFormModalConfig.model,
        ...record,
        ...getObjList(api_fields.update, record),
      };

      currentRecord = record;
    };
    const handleAdd = () => {
      formModalUpdate = false;
      editModalAdd.visible = true;

      editModalAdd.form.model = { ...formModalConfig.model };
      editModalAdd.title = titleList.addModalTitle;
    };

    const handleDelete = (record: any) => {
      Modal.confirm({
        title: () => t('page.pagemodel4.Delete_this'),
        icon: () => createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            removeData({
              org_id: current_org.id,
              ...getObjList(api_fields.delete, record),
            })
              .then((res: any) => {
                message.success(t('page.pagemodel4.Deleted_successfully'));
                Object.assign(record, res);
                state.dataSource = ([] as any).concat(state.dataSource);
              })
              .finally(() => {
                resolve(null);
              });
          }).catch(() => console.log('Oops errors!'));
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCancel() {},
      });
      // removeData({
      //   org_id: current_org.id,
      //   ...getObjList(api_fields.delete, record),
      // }).then((res: any) => {
      //   message.success('删除成功');
      //   Object.assign(record, res);
      // });
    };
    const handleRecover = (record: any) => {
      recoverData({
        org_id: current_org.id,
        ...getObjList(api_fields.recover, record),
      }).then((res: any) => {
        message.success(t('page.pagemodel4.recover_successful'));
        Object.assign(record, res);
        state.dataSource = ([] as any).concat(state.dataSource);
      });
    };

    const handlerOk = (data: any) => {
      confirmLoading.value = true;
      if (!formModalUpdate) {
        addData({ org_id: current_org.id, aim_org_id: editModalAdd.aim_org_id, ...data })
          .then(() => {
            message.success(t('page.pagemodel4.Created_successfully'));
            reload();
            editModalAdd.visible = false;
          })
          .finally(() => {
            confirmLoading.value = false;
          });
      } else {
        updateData({
          ...data,
          org_id: current_org.id,
          ...getObjList(api_fields.updateData, data),
          ...getObjList(api_fields.updateData, editModalUpdate.form.model),
        })
          .then((res: any) => {
            Object.assign(currentRecord, res);
            state.dataSource = ([] as any).concat(state.dataSource);
            message.success(t('page.pagemodel4.update_successfully'));
            editModalUpdate.visible = false;
            formModalUpdate = false;
          })
          .finally(() => {
            confirmLoading.value = false;
          });
      }
    };

    return {
      t,
      column_change,
      columnAction,
      titleList,
      tableHeight,
      state,
      stripe,
      columnState,
      dynamicColumns,
      dynamicColumnItems,
      summaryColumns,
      summaryColumnsShow,
      expandedRowRender,
      expandedRowRenderComponent,
      headerComponent,
      rowKey,

      confirmLoading,
      handleAdd,
      handlerOk,
      currentRecord,
      resetFields,
      validateInfos,
      // fullscreen
      elRef,
      screenState,
      setFull,
      exitFull,

      handleTableChange,
      search,
      handleReSize,

      configState,
      // is_column_config,
      switch_column_config,
      // change_columns_config,
      update_column_org_config,
      update_column_data,
      resizeColumn,
      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,
      current_org,
      message,
      // edit
      editModalAdd,
      editModalUpdate,
      handleOpenEdit,
      handleDelete,
      handleRecover,
      reload,
      getPageConfig,
      searchEle,
      searchConfig,
      searchConfigOptions,
      formModalConfig,
      formModalConfigOptions,
      updateFormModalConfig,
      updateFormModalConfigOptions,
      getObjList,
      field,
      api_fields,
      column_normal_btn_list,
      column_dropdown_btn_dict,
      head_btn_list,
      rowSelection: rowSelection ? rowSelection : null,
      selectedRowKeys: selectedRowKeys ? selectedRowKeys : null,
      customCell: customCell ? customCell : () => {},
      customRow: customRow ? customRow : () => {},
      customHeaderCell: customHeaderCell ? customHeaderCell : () => {},
    };
  },
  components: {
    // DragIcon,
    PlusOutlined,
    RedoOutlined,
    EditOutlined,
    DeleteOutlined,
    ReloadOutlined,
    // ColumnHeightOutlined,
    // SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    ForwardFilled,
    // Draggable,
    // DragContainer,
    FormModal,
    SearchRender,
  },
});
