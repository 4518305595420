import { query_receipt_contract_list } from '@/api/receipt-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ADVISER_KEY, STORAGE_CURRENT_COMPANY, STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/receipt/receipt-adviser-config';

import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { notification } from 'ant-design-vue';

const None = null;
const True = true;
const False = false;
const { t } = useI18n();
const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const adviser = ls.get(STORAGE_CURRENT_ADVISER_KEY);
const current_company = ls.get(STORAGE_CURRENT_COMPANY); // 当前组织org

const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    status: 4,
    adviser_id: adviser.adviser_id,
    cc_company_id: current_company?.id,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [],
  rules: {
    custom_4: [{ required: true, message: '请填输入自定义4' }],
    remark: [{ required: true, message: '请填输入备注' }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    date: null,
    invoice_id: null,
    custom_4: null,
    referral_fees: null,
    remark: null,
    cc_bank_account_id: null,
    bank_charge_cost: null,
    transaction_id: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [],
  rules: {},
  model: reactive({}),
};
const update_model_config_options = {};

const titleList = {
  title: t('票据合同'),
  addModalTitle: '',
  updateTitle: '',
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: false, // 是否有【删除】按钮
  is_recover: false, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    payslip_id: 'id',
  },
  delete: {
    payslip_id: 'id',
  },
  recover: {
    payslip_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    payslip_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.cerp.payslip.colums.column.title.index'),
    dataIndex: 'index',
    width: 50,
    fixed: 'left',
  },
  {
    title: t('合同起始日期'),
    minWidth: 130,
    dataIndex: 'start_date',
    checked: true,
  },
  {
    title: t('合同结束日期'),
    minWidth: 130,
    dataIndex: 'end_date',
    checked: true,
  },
  {
    title: t('CC公司'),
    minWidth: 130,
    dataIndex: 'cc_company__name',
    checked: true,
  },
  {
    title: t('page.cerp.contract.colums.column.title.appendix_json_list'),
    width: 200,
    dataIndex: 'appendix_json',
    checked: true,
    cellComponent: 'clicklist',
    cb: (record: any) => {
      if (record && record.file_url) {
        const url: string = 'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + record.file_url;
        window.open(url, '_blank');
      } else {
        notification.error({
          message: t('page.Induction.message.reminder'),
          description: t('page.Induction.openUrl.description'),
        });
      }
    },
  },
  // {
  //   title: t('操作'),
  //   width: 150,
  //   dataIndex: 'action',
  //   checked: true,
  //   cellComponent: 'action',
  //   fixed: 'right',
  // },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push();

export default () => {
  const route = useRoute();
  search_config.model = reactive({ ...search_config.model });
  search_config.model.cc_company_id = route.query.cc_company_id ? route.query.cc_company_id : current_company?.id;
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_receipt_contract_list,
    addData: null,
    updateData: null,
    removeData: null,
    recoverData: null,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
