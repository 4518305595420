import { TableColumn } from '@/typing';
import slotList from '@/components/page-model/slotList';
import { useI18n } from 'vue-i18n';
import { notification } from 'ant-design-vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { getDate } from '@/utils/function';

const { t } = useI18n();
const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
export const baseColumns: TableColumn[] = [
  {
    title: t('page.cerp.contract.colums.column.title.index'),
    dataIndex: 'index',
    width: 50,
    fixed: 'left',
  },
  {
    title: t('page.cerp.contract.colums.column.title.start_date'),
    minWidth: 150,
    dataIndex: 'start_date',
    checked: true,
    sorter: {
      multiple: 1,
    },
    content: (record: any, text: any) => {
      return getDate(text);
    },
  },
  {
    title: t('page.cerp.contract.colums.column.title.end_date'),
    minWidth: 150,
    dataIndex: 'end_date',
    checked: true,
    sorter: {
      multiple: 1,
    },
    content: (record: any, text: any) => {
      return getDate(text);
    },
  },
  {
    title: t('page.cerp.contract.colums.column.title.cc_company__name'),
    minWidth: 150,
    dataIndex: 'cc_company__name',
    checked: true,
  },
  // {
  //   title: t('page.cerp.contract.colums.column.title.appendix_json_list'),
  //   width: 200,
  //   dataIndex: 'appendix_json_list',
  //   checked: true,
  //   cellComponent: 'clicklist',
  //   cb: (record: any) => {
  //     if (record && record.file_url) {
  //       const url: string = 'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + record.file_url;
  //       window.open(url, '_blank');
  //     } else {
  //       notification.error({
  //         message: t('page.Induction.message.reminder'),
  //         description: t('page.Induction.openUrl.description'),
  //       });
  //     }
  //   },
  // },
  {
    title: t('page.cerp.contract.colums.column.title.appendix_json_list'),
    width: 200,
    dataIndex: 'wechat_appendix_json_list',
    checked: true,
    cellComponent: 'clicklist',
    cb: (record: any) => {
      if (record && record.file_url) {
        const url: string = 'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + record.file_url;
        window.open(url, '_blank');
      } else {
        notification.error({
          message: t('page.Induction.message.reminder'),
          description: t('page.Induction.openUrl.description'),
        });
      }
    },
  },
  // {
  //   title: t('page.cerp.contract.colums.column.title.qiyuesuo_status'),
  //   width: 150,
  //   dataIndex: 'qiyuesuo_status',
  //   checked: true,
  //   content: (record: any) => {
  //     let color = '';
  //     switch (record.qiyuesuo_status) {
  //       case 1:
  //         color = '草稿';
  //         break;
  //       case 2:
  //         color = '发起合同';
  //         break;
  //       case 3:
  //         color = '顾问签署';
  //         break;
  //       case 4:
  //         color = '签署完成';
  //         break;
  //       default:
  //         color = '无';
  //         break;
  //     }
  //     return color;
  //   },
  // },
  {
    title: t('page.cerp.contract.colums.column.title.is_current'),
    width: 150,
    dataIndex: 'is_current',
    checked: true,
    cellComponent: 'boolean',
    name1: t('page.Induction.options_yin_hang_ZH.is_current.true'),
    name2: t('page.Induction.options_yin_hang_ZH.is_current.false'),
  },

  // {
  //   title: t('page.cerp.contract.colums.column.title.action'),
  //   width: 120,
  //   dataIndex: 'action',
  //   checked: false,
  //   cellComponent: 'action',
  //   fixed: 'right',
  // },
];
const newSlotList1: any = [];
Object.assign(newSlotList1, slotList);
export const newSlotList = newSlotList1;
