import { reactive } from 'vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { create_end_client, create_email_address } from '@/api/baseinfo-model';
import modalBox from '@/components/form-modal/modal-tools';
import { message } from 'ant-design-vue';

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

export const settings = {
  labelAlign: 'right',
  layout: 'vertical',
  col: [22, 20, 19, 20],
};
export const get_fields = (obj: any) => {
  const fields = [
    {
      type: 'remoteselect',
      name: 'creater_id',
      label: '创建人',
      disabled: false,
      placeholder: '请选择创建人',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'realname',
      valueKey: 'id',
      modalType: 'sys-userlist',
    },
    {
      type: 'title',
      name: 'Basic Information',
    },
    {
      type: 'radio',
      name: 'is_current',
      label: '是否当前合同',
      disabled: obj.is_current,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'rangepicker',
      name: 'start_end_date',
      label: '合同起始结束日期',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写合同起始结束日期',
    },
    {
      type: 'title',
      name: 'Email Templates',
    },
    {
      type: 'remoteselect',
      name: 'recuitment_company_person_id',
      label: '请款邮箱地址',
      disabled: false,
      placeholder: '请选择请款邮箱地址',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'name',
      valueKey: 'id',
      modalType: 'baseinfo-recuitmentcompanyperson1',
    },
    {
      type: 'remoteselect',
      name: 'contract_template_id',
      label: '合同模板',
      disabled: false,
      placeholder: '请选择合同模板',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-contracttemplate',
    },
    {
      type: 'remoteselect',
      name: 'cc_email_id',
      label: 'CC邮箱',
      disabled: false,
      placeholder: '请选择CC邮箱',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-ccemail',
    },
    {
      type: 'remoteselect',
      name: 'cc_expense_email_template_id',
      label: 'CC报销请款单邮箱模板',
      disabled: false,
      placeholder: '请选择CC报销请款单邮箱模板',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'title',
      name: 'Salary Information',
    },
    {
      type: 'money',
      name: 'basic_rate',
      label: '基本单位薪资',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写基本单位薪资',
    },
    {
      type: 'number',
      name: 'accommodation_rate',
      label: '住宿费率',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写住宿费率',
    },
    {
      type: 'title',
      name: 'Local Salary',
    },
    {
      type: 'money',
      name: 'local_management_fee_fixed',
      label: '本地发薪工资',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写本地发薪工资',
    },
    {
      type: 'title',
      name: 'Management fees',
    },
    {
      type: 'money',
      name: 'cc_management_fee',
      label: 'cc管理费',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写cc管理费',
    },
    {
      type: 'money',
      name: 'local_management_fee',
      label: '本地管理费',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写本地管理费',
    },
    {
      type: 'kong_title',
      height: 10,
    },
    {
      type: 'title',
      name: 'CC Insurance',
    },
    {
      type: 'select',
      name: 'insurance_type',
      label: '保险类型',
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择保险类型',
      datasource: 'insurance_type',
    },
    {
      type: 'title',
      name: 'Notes',
    },
    {
      type: 'textarea',
      name: 'remark',
      label: '备注',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 200,
      defaultValue: '',
      placeholder: '请填写备注',
    },
    {
      type: 'remoteselect',
      name: 'end_client_id',
      label: '终端用户',
      disabled: false,
      placeholder: '请选择终端用户',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'name',
      valueKey: 'id',
      modalType: 'baseinfo-endclient',
      cb: () => {
        const p = new Promise(function (resolve, reject) {
          const editModal1 = reactive({
            visible: true,
            title: '新建终端用户',
            loading: false,
            form: {
              settings: {
                labelAlign: 'right',
                layout: 'vertical',
                col: [],
              },
              fields: [
                {
                  type: 'input',
                  name: 'name',
                  label: '名称',
                  disabled: false,
                  allowClear: true,
                  inputType: 'text',
                  defaultValue: '',
                  placeholder: '请填写名称',
                },
                {
                  type: 'input',
                  name: 'address',
                  label: '地址',
                  disabled: false,
                  allowClear: true,
                  inputType: 'text',
                  defaultValue: '',
                  placeholder: '请填写地址',
                },
                {
                  type: 'input',
                  name: 'contact_number',
                  label: '联系方式',
                  disabled: false,
                  allowClear: true,
                  inputType: 'text',
                  defaultValue: '',
                  placeholder: '请填写联系方式',
                },
              ],
              rules: {
                name: [{ required: true, message: '请填输入名称' }],
              },
              model: reactive({
                org_id: current_org?.id,
                name: null,
                address: null,
                contact_number: null,
              }),
            },
            options: {
              gender: [
                { value: 0, name: '男' },
                { value: 1, name: '女' },
              ],
            },
            ok: (payload: any) => {
              return new Promise(resolve1 => {
                editModal1.loading = true;
                create_end_client(payload)
                  .then((res: any) => {
                    message.success('创建成功');
                    resolve(res.id as number);
                    resolve1(null);
                  })
                  .finally(() => {
                    editModal1.loading = false;
                  });
              });
            },
          });
          modalBox(editModal1);
        });
        return p;
      },
    },
    {
      type: 'kong_title',
      height: 30,
    },
    {
      type: 'input',
      name: 'adviser__full_name',
      label: '顾问',
      disabled: true,
      placeholder: '请选择隶属顾问',
      datasourceType: 'remote',
      allowClear: false,
    },
    {
      type: 'remoteselect',
      name: 'work_location_id',
      label: '工作地点',
      disabled: false,
      placeholder: '请选择工作地点',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-country',
    },
    {
      type: 'kong_title',
      height: 30,
    },
    {
      type: 'remoteselect',
      name: 'invoice_email_template_id',
      label: '请款单邮箱模板',
      disabled: false,
      placeholder: '请选择请款单邮箱模板',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'contract_email_template_id',
      label: '合同邮箱模板',
      disabled: false,
      placeholder: '请选择合同邮箱模板',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'expense_email_template_id',
      label: '报销请款单邮箱模板',
      disabled: false,
      placeholder: '请选择报销请款单邮箱模板',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'kong',
    },
    {
      type: 'kong_title',
      height: 30,
    },
    {
      type: 'remoteselect',
      name: 'currency_id',
      labelKeyList: ['code', 'currency'],
      label: '薪资币种',
      disabled: false,
      placeholder: '请选择薪资币种',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-country',
    },
    {
      type: 'number',
      name: 'overtime_rate',
      label: '加班费率',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写加班费率',
    },
    {
      type: 'kong_title',
      height: 30,
    },
    {
      type: 'number',
      name: 'local_management_fee_rate',
      label: '本地管理费率（%）',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写本地管理费率',
    },
    {
      type: 'kong_title',
      height: 30,
    },
    {
      type: 'number',
      name: 'cc_management_fee_rate',
      label: 'cc管理费率（%）',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      min: 0,
      placeholder: '请填写cc管理费率',
    },
    {
      type: 'money',
      name: 'bank_charge',
      label: '银行转账费',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写银行转账费',
    },
    {
      type: 'kong_title',
      height: 30,
    },
    {
      type: 'kong_title',
      height: 10,
    },
    {
      type: 'radio',
      name: 'insurance_payment_flag',
      label: '保险费支付方式',
      disabled: obj.insurance_fee,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'remoteselect',
      name: 'cc_company_id',
      label: 'CC公司',
      disabled: false,
      placeholder: '请选择CC公司',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-cccompany',
    },
    {
      type: 'kong_title',
      height: 30,
    },
    {
      type: 'input',
      name: 'consultant',
      label: '职务',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写职务',
    },
    {
      type: 'remoteselect',
      name: 'recuitment_company_id',
      label: '顾问公司',
      disabled: false,
      placeholder: '请选择顾问公司',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'company_name',
      valueKey: 'id',
      modalType: 'baseinfo-recuitmentcompany',
    },
    {
      type: 'kong_title',
      height: 30,
    },
    {
      type: 'remoteselect',
      name: 'adviser_email_template_id',
      label: '请款时顾问提示邮箱模板',
      disabled: false,
      placeholder: '请选择请款时顾问提示邮箱模板',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'email_address_ids',
      label: '合同发送抄送邮箱',
      disabled: false,
      placeholder: '请选择合同发送抄送邮箱',
      datasourceType: 'remote',
      mode: 'multiple',
      allowClear: true,
      labelKey: 'name',
      valueKey: 'id',
      modalType: 'baseinfo-emailaddress',
      cb: () => {
        const p = new Promise(function (resolve, reject) {
          const editModal1 = reactive({
            visible: true,
            title: '新建收件箱',
            loading: false,
            form: {
              settings: {
                labelAlign: 'right',
                layout: 'vertical',
                col: [],
              },
              fields: [
                {
                  type: 'input',
                  name: 'name',
                  label: '邮箱名称',
                  disabled: false,
                  allowClear: true,
                  inputType: 'text',
                  defaultValue: '',
                  placeholder: '请填写邮箱名称',
                },
                {
                  type: 'input',
                  name: 'email',
                  label: '邮箱地址',
                  disabled: false,
                  allowClear: true,
                  inputType: 'text',
                  defaultValue: '',
                  placeholder: '请填写邮箱地址',
                },
              ],
              rules: {
                name: [{ required: true, message: '请填输入邮箱名称' }],
                email: [{ required: true, message: '请填输入邮箱地址' }],
              },
              model: reactive({
                org_id: current_org?.id,
                name: null,
                email: null,
              }),
            },
            options: {},
            ok: (payload: any) => {
              return new Promise(resolve1 => {
                editModal1.loading = true;
                create_email_address(payload)
                  .then((res: any) => {
                    message.success('创建成功');
                    resolve(res.id as number);
                    resolve1(null);
                  })
                  .finally(() => {
                    editModal1.loading = false;
                  });
              });
            },
          });
          modalBox(editModal1);
        });
        return p;
      },
    },
    {
      type: 'remoteselect',
      name: 'overtime_email_template_id',
      label: '加班请款单邮箱模板',
      disabled: false,
      placeholder: '请选择加班请款单邮箱模板',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'kong',
    },
    {
      type: 'kong_title',
      height: 30,
    },
    {
      type: 'select',
      name: 'rate_type',
      label: '薪资种类',
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择薪资种类',
      datasource: 'rate_type',
    },
    {
      type: 'kong',
    },
    {
      type: 'kong_title',
      height: 30,
    },
    {
      type: 'money',
      name: 'local_deduct_fee',
      label: '本地扣减费',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写本地扣减费',
    },
    {
      type: 'kong_title',
      height: 30,
    },
    {
      type: 'radio',
      name: 'management_fee_payment_flag',
      label: '管理费支付方式',
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },

    {
      type: 'radio',
      name: 'is_bank_charge_payment',
      label: '银行转账费支付方式',
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'kong_title',
      height: 65,
    },
    {
      type: 'money',
      name: 'insurance_fee',
      label: '顾问保险费',
      disabled: obj.is_insurance_fee,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写顾问保险费',
    },
    {
      type: 'kong',
    },
    {
      type: 'kong_title',
      height: 30,
    },
    {
      type: 'input',
      name: 'project_name',
      label: '项目名称',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写项目名称',
    },
    {
      type: 'remoteselect',
      name: 'contact_person_id',
      label: '顾问公司联系人',
      disabled: false,
      placeholder: '请选择顾问公司联系人',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'name',
      valueKey: 'id',
      modalType: 'baseinfo-recuitmentcompanyperson1',
    },
    {
      type: 'kong_title',
      height: 30,
    },
    {
      type: 'remoteselect',
      name: 'invoice_cc_address_ids',
      label: '请款抄送邮箱地址',
      disabled: false,
      placeholder: '请选择请款抄送邮箱地址',
      datasourceType: 'remote',
      mode: 'multiple',
      allowClear: true,
      labelKey: 'name',
      valueKey: 'id',
      modalType: 'baseinfo-recuitmentcompanyperson',
    },
    {
      type: 'remoteselect',
      name: 'payslip_email_template_id',
      label: '工资单邮箱模板',
      disabled: false,
      placeholder: '请选择工资单邮箱模板',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'cn_email_template_id',
      label: 'CN请款单邮箱模板',
      disabled: false,
      placeholder: '请选择CN请款单邮箱模板',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'kong',
    },
    {
      type: 'kong_title',
      height: 30,
    },
    {
      type: 'money',
      name: 'local_salary',
      label: '本地发薪数值',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写本地发薪数值',
    },
    {
      type: 'kong',
    },
    {
      type: 'kong_title',
      height: 30,
    },
    {
      type: 'remoteselect',
      name: 'local_pay_currency_id',
      labelKeyList: ['code', 'currency'],
      label: '本地发薪币种',
      disabled: false,
      placeholder: '请选择本地发薪币种',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-country',
    },
    {
      type: 'kong_title',
      height: 30,
    },
    {
      type: 'remoteselect',
      name: 'management_fee_formula_id',
      label: '管理费计算方式',
      disabled: false,
      placeholder: '请选择管理费计算方式',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-managementfeeformula',
    },
    {
      type: 'input',
      name: 'manage_fee',
      label: '管理费描述',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写管理费描述',
    },
    {
      type: 'kong_title',
      height: 88,
    },
    {
      type: 'money',
      name: 'c_insurance_fee',
      label: '顾问公司保险费',
      disabled: obj.is_c_insurance_fee,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写顾问公司保险费',
    },
  ];
  return fields;
};
export const fields = [];
export const options = {
  is_current: [
    { value: true, name: '是' },
    { value: false, name: '否' },
  ],
  rate_type: [
    { value: 0, name: '日薪' },
    { value: 1, name: '月薪' },
    { value: 2, name: '年薪' },
    { value: 3, name: '时薪' },
    { value: 4, name: '周薪' },
  ],
  is_bank_charge_payment: [
    { value: true, name: '顾问支付' },
    { value: false, name: '顾问公司支付' },
  ],
  insurance_payment_flag: [
    { value: -1, name: '无' },
    { value: 0, name: '顾问支付' },
    { value: 1, name: '顾问公司支付' },
  ],
  management_fee_payment_flag: [
    { value: 0, name: '顾问支付' },
    { value: 1, name: '顾问公司支付' },
  ],
  insurance_type: [
    { value: 0, name: '需要' },
    { value: 1, name: '不需要–顾问在本地' },
    { value: 2, name: '不需要–顾问自己有保单' },
    { value: 3, name: '不需要–顾问公司保险' },
  ],
};
