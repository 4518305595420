import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, mergeProps as _mergeProps, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "ant-pro-table" }
const _hoisted_2 = {
  key: 0,
  class: "ant-pro-table-search",
  ref: "searchEle"
}
const _hoisted_3 = { class: "ant-pro-table-list-toolbar" }
const _hoisted_4 = { class: "ant-pro-table-list-toolbar-container" }
const _hoisted_5 = { class: "ant-pro-table-list-toolbar-left" }
const _hoisted_6 = { class: "ant-pro-table-list-toolbar-title" }
const _hoisted_7 = { class: "ant-pro-table-list-toolbar-right" }
const _hoisted_8 = {
  key: 0,
  class: "ant-pro-table-list-toolbar-setting-item"
}
const _hoisted_9 = { class: "ant-pro-table-list-toolbar-setting-item" }
const _hoisted_10 = {
  key: 1,
  class: "ant-pro-table-list-toolbar-setting-item"
}
const _hoisted_11 = { class: "ant-pro-table-list-toolbar-divider" }
const _hoisted_12 = { class: "ant-pro-table-list-toolbar-setting-item" }
const _hoisted_13 = { class: "ant-pro-table-list-toolbar-setting-item" }
const _hoisted_14 = { class: "ant-pro-table-list-toolbar-setting-item" }
const _hoisted_15 = { href: "javascript:" }
const _hoisted_16 = { href: "javascript:" }
const _hoisted_17 = { href: "javascript:" }
const _hoisted_18 = { class: "ant-pro-table-list-toolbar-setting-item" }
const _hoisted_19 = { class: "ant-pro-table-column-setting-title" }
const _hoisted_20 = { class: "ant-pro-table-column-setting-list" }
const _hoisted_21 = { class: "ant-pro-table-column-setting-list-item" }
const _hoisted_22 = { class: "ant-pro-table-list-toolbar-setting-item" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_render = _resolveComponent("search-render")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_reload_outlined = _resolveComponent("reload-outlined")!
  const _component_export_excel_modal = _resolveComponent("export-excel-modal")!
  const _component_column_height_outlined = _resolveComponent("column-height-outlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_drag_icon = _resolveComponent("drag-icon")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_drag_container = _resolveComponent("drag-container")!
  const _component_setting_outlined = _resolveComponent("setting-outlined")!
  const _component_a_popover = _resolveComponent("a-popover")!
  const _component_fullscreen_outlined = _resolveComponent("fullscreen-outlined")!
  const _component_fullscreen_exit_outlined = _resolveComponent("fullscreen-exit-outlined")!
  const _component_s_table_summary_cell = _resolveComponent("s-table-summary-cell")!
  const _component_s_table_summary_row = _resolveComponent("s-table-summary-row")!
  const _component_s_table = _resolveComponent("s-table")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_form_modal = _resolveComponent("form-modal")!
  const _component_page_container3 = _resolveComponent("page-container3")!

  return (_openBlock(), _createBlock(_component_page_container3, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.searchConfig.fields.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_search_render, {
                ref: "config_render",
                form: _ctx.searchConfig,
                model: _ctx.searchConfig.model,
                options: _ctx.searchConfigOptions,
                validateInfos: _ctx.validateInfos,
                resetFields: _ctx.resetFields,
                onSearch: _ctx.search,
                onResize: _ctx.handleReSize
              }, null, 8, ["form", "model", "options", "validateInfos", "resetFields", "onSearch", "onResize"])
            ], 512))
          : _createCommentVNode("", true),
        _createVNode(_component_a_card, {
          "body-style": { padding: 0 },
          ref: "elRef"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.titleList.title), 1),
                  (_ctx.headerComponent)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.headerComponent), _normalizeProps(_mergeProps({ key: 0 }, _ctx.state)), null, 16))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_a_space, { align: "center" }, {
                    default: _withCtx(() => [
                      (_ctx.titleList.is_create)
                        ? (_openBlock(), _createBlock(_component_a_button, {
                            key: 0,
                            type: "primary",
                            size: 'small',
                            onClick: _ctx.handleAdd
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_plus_outlined),
                              _createTextVNode(" " + _toDisplayString(_ctx.titleList.addModalTitle), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.head_btn_list, (btn) => {
                        return (_openBlock(), _createBlock(_component_a_button, {
                          size: 'small',
                          key: btn.name,
                          onClick: ($event: any) => (btn.cb(_ctx.searchConfig.model, _ctx.state.dataSource, _ctx.selectedRowKeys, _ctx.searchConfigOptions)),
                          type: btn.clazz
                        }, {
                          default: _withCtx(() => [
                            (btn.icon === 'plus-outlined')
                              ? (_openBlock(), _createBlock(_component_plus_outlined, { key: 0 }))
                              : _createCommentVNode("", true),
                            _createTextVNode(" " + _toDisplayString(btn.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick", "type"]))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  (_ctx.configState.is_column_config)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createVNode(_component_a_tooltip, {
                          title: _ctx.t('page.pagemodel4.Basic_column')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_button, {
                              size: 'small',
                              type: "primary",
                              danger: "",
                              onClick: _ctx.update_column_org_config
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('page.pagemodel4.Set_as_basic_configuration')), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        }, 8, ["title"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_a_tooltip, {
                      title: _ctx.t('page.pagemodel4.Show_custom_columns')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_switch, {
                          "checked-children": _ctx.t('page.pagemodel4.user'),
                          "un-checked-children": _ctx.t('page.pagemodel4.original'),
                          checked: _ctx.configState.checkAll,
                          "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.configState.checkAll) = $event)),
                          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.column_change()))
                        }, null, 8, ["checked-children", "un-checked-children", "checked"])
                      ]),
                      _: 1
                    }, 8, ["title"])
                  ]),
                  (_ctx.configState.showCheckAction)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createVNode(_component_a_tooltip, {
                          title: _ctx.t('page.pagemodel4.column')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_switch, {
                              "checked-children": _ctx.t('page.pagemodel4.operation'),
                              "un-checked-children": _ctx.t('page.pagemodel4.operation'),
                              checked: _ctx.configState.checkAction,
                              "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.configState.checkAction) = $event)),
                              onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.columnAction()))
                            }, null, 8, ["checked-children", "un-checked-children", "checked"])
                          ]),
                          _: 1
                        }, 8, ["title"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_a_divider, { type: "vertical" })
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_a_tooltip, {
                      title: _ctx.t('page.pagemodel4.Refresh')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_reload_outlined, { onClick: _ctx.handleTableChange }, null, 8, ["onClick"])
                      ]),
                      _: 1
                    }, 8, ["title"])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_a_tooltip, {
                      title: _ctx.t('page.pagemodel4.Export_Excel')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_export_excel_modal, {
                          "column-list": _ctx.dynamicColumnItems,
                          datasource: _ctx.state.dataSource,
                          filename: _ctx.titleList.title,
                          columns: _ctx.dynamicColumns,
                          pageSize: _ctx.state.pageSize,
                          current: _ctx.state.current
                        }, null, 8, ["column-list", "datasource", "filename", "columns", "pageSize", "current"])
                      ]),
                      _: 1
                    }, 8, ["title"])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_a_tooltip, {
                      title: _ctx.t('page.pagemodel4.density')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_dropdown, {
                          trigger: ['click'],
                          placement: "bottomRight"
                        }, {
                          overlay: _withCtx(() => [
                            _createVNode(_component_a_menu, {
                              style: {"width":"80px"},
                              "selected-keys": [_ctx.state.tableSize],
                              onClick: _cache[4] || (_cache[4] = 
                          ({ key }) => {
                            _ctx.state.tableSize = key;
                          }
                        )
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_menu_item, { key: "default" }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("a", _hoisted_15, _toDisplayString(_ctx.t('page.pagemodel4.default')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_menu_item, { key: "middle" }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("a", _hoisted_16, _toDisplayString(_ctx.t('page.pagemodel4.middle')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_menu_item, { key: "small" }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("a", _hoisted_17, _toDisplayString(_ctx.t('page.pagemodel4.small')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["selected-keys"])
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_column_height_outlined)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["title"])
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createVNode(_component_a_popover, {
                      placement: "bottomRight",
                      arrowPointAtCenter: "",
                      trigger: "click",
                      overlayClassName: "ant-pro-table-column-setting-overlay"
                    }, {
                      title: _withCtx(() => [
                        _createElementVNode("div", _hoisted_19, [
                          _createVNode(_component_a_checkbox, {
                            checked: _ctx.columnState.checkAll,
                            "onUpdate:checked": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.columnState.checkAll) = $event)),
                            indeterminate: _ctx.columnState.indeterminate,
                            onChange: _ctx.handleColumnAllClick
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t('page.pagemodel4.Column_display')), 1)
                            ]),
                            _: 1
                          }, 8, ["checked", "indeterminate", "onChange"]),
                          (!_ctx.configState.is_column_config)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.switch_column_config && _ctx.switch_column_config(...args)))
                              }, _toDisplayString(_ctx.t('page.pagemodel4.configure')), 1))
                            : _createCommentVNode("", true),
                          (_ctx.configState.is_column_config)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 1,
                                onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.switch_column_config && _ctx.switch_column_config(...args)))
                              }, _toDisplayString(_ctx.t('page.pagemodel4.Close_configuration')), 1))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      content: _withCtx(() => [
                        _createElementVNode("span", _hoisted_20, [
                          _createVNode(_component_drag_container, {
                            style: {"width":"280px","height":"300px","overflow":"scroll"},
                            lockAxis: "y",
                            dragClass: "ant-pro-table-drag-ghost",
                            dropClass: "ant-pro-table-drop-ghost",
                            onDrop: _cache[8] || (_cache[8] = 
                          ({ removedIndex, addedIndex }) => {
                            _ctx.move(removedIndex, addedIndex);
                            if (!_ctx.configState.is_column_config) {
                              _ctx.update_column_data();
                            }
                          }
                        )
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dynamicColumnItems, (column) => {
                                return (_openBlock(), _createBlock(_component_draggable, {
                                  key: column.key
                                }, {
                                  default: _withCtx(() => [
                                    _withDirectives(_createElementVNode("div", _hoisted_21, [
                                      _createVNode(_component_drag_icon),
                                      _createVNode(_component_a_checkbox, {
                                        checked: column.checked,
                                        onChange: 
                                $event => {
                                  _ctx.handleColumnChange($event, column);
                                  if (!_ctx.configState.is_column_config) {
                                    _ctx.update_column_data();
                                  }
                                }
                              
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(column.label), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["checked", "onChange"]),
                                      (_ctx.configState.is_column_config)
                                        ? (_openBlock(), _createBlock(_component_a_switch, {
                                            key: 0,
                                            "checked-children": _ctx.t('page.pagemodel4.display'),
                                            "un-checked-children": _ctx.t('page.pagemodel4.operation'),
                                            checked: column.org_checked,
                                            "onUpdate:checked": ($event: any) => ((column.org_checked) = $event),
                                            onChange: 
                              () => {
                                if (!column.org_checked) {
                                  column.checked = false;
                                }
                                _ctx.handleColumnChange({ target: { checked: column.checked } }, column);
                              }
                            
                                          }, null, 8, ["checked-children", "un-checked-children", "checked", "onUpdate:checked", "onChange"]))
                                        : _createCommentVNode("", true)
                                    ], 512), [
                                      [_vShow, _ctx.configState.is_column_config ? true : column.org_checked]
                                    ])
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_tooltip, {
                          title: _ctx.t('page.pagemodel4.Column_settings')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_setting_outlined)
                          ]),
                          _: 1
                        }, 8, ["title"])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _createVNode(_component_a_tooltip, {
                      title: _ctx.screenState ? _ctx.t('page.pagemodel4.Exit_full_screen') : _ctx.t('page.pagemodel4.full_screen')
                    }, {
                      default: _withCtx(() => [
                        (!_ctx.screenState)
                          ? (_openBlock(), _createBlock(_component_fullscreen_outlined, {
                              key: 0,
                              onClick: _ctx.setFull
                            }, null, 8, ["onClick"]))
                          : (_openBlock(), _createBlock(_component_fullscreen_exit_outlined, {
                              key: 1,
                              onClick: _ctx.exitFull
                            }, null, 8, ["onClick"]))
                      ]),
                      _: 1
                    }, 8, ["title"])
                  ])
                ])
              ])
            ]),
            _createVNode(_component_s_table, {
              bordered: true,
              height: _ctx.tableHeight.y,
              size: _ctx.state.tableSize,
              columns: _ctx.dynamicColumns,
              "onUpdate:columns": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.dynamicColumns) = $event)),
              "data-source": _ctx.state.dataSource,
              indentSize: 0,
              autoRowHeight: true,
              wrapText: true,
              rowHeight: undefined,
              rowKey: _ctx.rowKey ? _ctx.rowKey : 'id',
              loading: _ctx.state.loading,
              virtual: _ctx.state.dataSource.lenght > 1000,
              customRow: _ctx.customRow,
              customHeaderCell: _ctx.customHeaderCell,
              customCell: _ctx.customCell,
              stripe: "",
              sticky: "",
              "summary-fixed": "",
              pagination: {
            current: _ctx.state.current,
            pageSize: _ctx.state.pageSize,
            total: _ctx.state.total,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['10', '20', '50', '200', '1000', '3000', '10000', '50000', '100000'],
          },
              onChange: _ctx.handleTableChange,
              onResizeColumn: _ctx.resizeColumn,
              rowSelection: _ctx.rowSelection,
              expandedRowRender: _ctx.expandedRowRender,
              selectedRowKeys: _ctx.selectedRowKeys,
              "onUpdate:selectedRowKeys": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.selectedRowKeys) = $event))
            }, _createSlots({
              bodyCell: _withCtx(({ column, index, text, record }) => [
                (column.dataIndex == 'index')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(index + 1 + _ctx.state.pageSize * (_ctx.state.current - 1)), 1)
                    ], 64))
                  : (column.cellComponent)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent('Cell_' + column.cellComponent), _normalizeProps(_mergeProps({ key: 1 }, {
                column: column,
                index: index,
                text: text,
                record: record,
                pageSize: _ctx.state.pageSize,
                current: _ctx.state.current,
                titleList: _ctx.titleList,
                column_normal_btn_list: _ctx.column_normal_btn_list,
                column_dropdown_btn_dict: _ctx.column_dropdown_btn_dict,
                handleOpenEdit: _ctx.handleOpenEdit,
                handleDelete: _ctx.handleDelete,
                handleRecover: _ctx.handleRecover,
              })), null, 16))
                    : (_openBlock(), _createElementBlock("span", {
                        key: 2,
                        style: _normalizeStyle(column.color ? column.color(record, text) : null)
                      }, _toDisplayString(column.content ? column.content(record, text) : text), 5))
              ]),
              _: 2
            }, [
              (_ctx.expandedRowRenderComponent)
                ? {
                    name: "expandedRowRender",
                    fn: _withCtx(({ record }) => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.expandedRowRenderComponent(record)), _normalizeProps(_guardReactiveProps(record)), null, 16))
                    ])
                  }
                : undefined,
              (_ctx.summaryColumnsShow)
                ? {
                    name: "summary",
                    fn: _withCtx(() => [
                      _createVNode(_component_s_table_summary_row, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summaryColumns, (item) => {
                            return (_openBlock(), _createBlock(_component_s_table_summary_cell, {
                              columnKey: item.columnKey,
                              key: item.index
                            }, _createSlots({
                              default: _withCtx(() => [
                                (item.index == 0)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString(_ctx.t('page.pagemodel4.total')), 1))
                                  : (!item.summary)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_24, " "))
                                    : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, [
                              (item.summary)
                                ? {
                                    name: "default",
                                    fn: _withCtx(({ total }) => [
                                      _createElementVNode("span", {
                                        style: _normalizeStyle(item.total_color ? item.total_color(total) : null)
                                      }, _toDisplayString(item.total_content ? item.total_content(total) : total), 5)
                                    ])
                                  }
                                : undefined
                            ]), 1032, ["columnKey"]))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ])
                  }
                : undefined
            ]), 1032, ["height", "size", "columns", "data-source", "rowKey", "loading", "virtual", "customRow", "customHeaderCell", "customCell", "pagination", "onChange", "onResizeColumn", "rowSelection", "expandedRowRender", "selectedRowKeys"])
          ]),
          _: 1
        }, 512)
      ]),
      (_ctx.editModalAdd.visible)
        ? (_openBlock(), _createBlock(_component_form_modal, _mergeProps({ key: 0 }, _ctx.editModalAdd, {
            loading: _ctx.confirmLoading,
            onCancel: _cache[11] || (_cache[11] = 
        () => {
          _ctx.editModalAdd.visible = false;
        }
      ),
            onOk: _ctx.handlerOk
          }), null, 16, ["loading", "onOk"]))
        : _createCommentVNode("", true),
      (_ctx.editModalUpdate.visible)
        ? (_openBlock(), _createBlock(_component_form_modal, _mergeProps({ key: 1 }, _ctx.editModalUpdate, {
            loading: _ctx.confirmLoading,
            onCancel: _cache[12] || (_cache[12] = 
        () => {
          _ctx.editModalUpdate.visible = false;
        }
      ),
            onOk: _ctx.handlerOk
          }), null, 16, ["loading", "onOk"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}