import {
  create_insurance,
  delete_insurance,
  query_insurance_list,
  recover_insurance,
  update_insurance,
} from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ADVISER_KEY, STORAGE_CURRENT_ORG_KEY } from "@/store/mutation-type";
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/cerp/cerp-insurance-config';
import { createVNode, reactive, ref } from 'vue';
import { message, Modal, notification } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';
import { getDate, getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const adviser = ls.get(STORAGE_CURRENT_ADVISER_KEY);

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields:[],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    adviser_id: adviser.adviser_id,
    insurance_company_id: null,
    policy_no: null,
    effective_date_time: null,
    expiry_date_time: null,
    is_current: null,
    insurance_fee: null,
  }),
};
const search_options = {
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields:[],
  rules: {
 },
  model: reactive({
  }),
};
const edit_model_config_options = {
};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields:[],
  rules: {},
  model: reactive({
  }),
};
const update_model_config_options = {
};

const titleList = {
  title: t('router.title.cerp-insurance'),
  addModalTitle: t('新建保险'),
  updateTitle: t('修改保险'),
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: false, // 是否有【删除】按钮
  is_recover: false, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    insurance_id: 'id',
  },
  delete: {
    insurance_id: 'id',
  },
  recover: {
    insurance_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    insurance_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.cerp.insurance.column.title.index'),
    dataIndex: 'index',
    width: 50,
    fixed: 'left',
  },
  {
    title: t('page.cerp.insurance.column.title.insurance_company.company_name'),
    minWidth: 200,
    dataIndex: 'insurance_company__company_name',
    checked: true,
  },
  {
    title: t('page.cerp.insurance.column.title.policy_no'),
    width: 150,
    dataIndex: 'policy_no',
    checked: true,
  },
  {
    title: t('page.cerp.insurance.column.title.effective_date_time'),
    width: 170,
    dataIndex: 'effective_date_time',
    checked: true,
    sorter: {
      multiple: 1,
    },
    content: (record: any) => {
      return getDate(record.effective_date_time);
    },
  },
  {
    title: t('page.cerp.insurance.column.title.expiry_date_time'),
    width: 170,
    dataIndex: 'expiry_date_time',
    checked: true,
    sorter: {
      multiple: 1,
    },
    content: (record: any) => {
      return getDate(record.expiry_date_time);
    },
  },
  {
    title: t('page.cerp.insurance.column.title.beneficiaries'),
    width: 150,
    dataIndex: 'beneficiaries',
    checked: true,
    cellComponent: 'list',
    listKey: 'name',
  },
  // {
  //   title: t('page.cerp.insurance.column.title.insurance_type__name'),
  //   width: 150,
  //   dataIndex: 'insurance_type__name',
  //   checked: true,
  // },
  // {
  //   title: t('page.cerp.insurance.column.title.insurance_fee'),
  //   width: 150,
  //   dataIndex: 'insurance_fee',
  //   checked: true,
  //   cellComponent: 'money',
  // },
  {
    title: t('page.cerp.insurance.column.title.month_insurance_fee'),
    width: 150,
    dataIndex: 'month_insurance_fee',
    checked: true,
    cellComponent: 'money',
  },
  // {
  //   title: t('page.cerp.insurance.column.title.remark'),
  //   width: 150,
  //   dataIndex: 'remark',
  //   checked: true,
  // },
  {
    title: t('page.cerp.insurance.column.title.appendix_json'),
    width: 200,
    dataIndex: 'appendix_json',
    checked: true,
    cellComponent: 'clicklist',
    cb: (record: any) => {
      if (record && record.file_url) {
        const loading = ref(false);
        const url: string = 'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.file_url);
        window.open(url, '_blank');
      } else {
        notification.error({
          message: t('page.Induction.message.reminder'),
          description: t('page.Induction.openUrl.description'),
        });
      }
    },
  },
  {
    title: t('page.cerp.insurance.column.title.is_current'),
    // width: 150,
    dataIndex: 'is_current',
    checked: true,
    cellComponent: 'boolean',
    name1: t('page.Induction.options_yin_hang_ZH.is_current.true'),
    name2: t('page.Induction.options_yin_hang_ZH.is_current.false'),
  },
  // {
  //   title: t('page.cerp.insurance.column.title.action'),
  //   width: 250,
  //   dataIndex: 'action',
  //   checked: false,
  //   cellComponent: 'action',
  //   fixed: 'right',
  // },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push(
  {
    type: 'custom',
    // slotName: 'is_active',
    field_true: '可用',
    field_false: '禁用',
  },
  {
    type: 'clickList',
    slotName: 'appendix_json',
    cb: (record: any) => {
      if (record && record.file_url) {
        const loading = ref(false);
        const url: string = 'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.file_url);
        window.open(url, '_blank');
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
    del: (record: any, text: any, file: any) => {
      if (file.file_id) {
        Modal.confirm({
          title: t('提示'),
          content: t('是否删除') + '[' + file.name + ']',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            return new Promise(resolve => {
              let ids: any = [];
              text.forEach((itme: any) => {
                if (itme.file_id != file.file_id) {
                  ids.push(itme.file_id);
                }
              });
              if (ids.length == 0) {
                ids = 0;
              }
              update_insurance({
                insurance_id: record.id,
                appendix_ids: ids,
                org_id: current_org?.id,
              })
                .then(() => {
                  message.success(t('删除成功'));
                  event_obj.emit('refresh');
                })
                .finally(() => {
                  resolve(null);
                });
            });
          },
          onCancel() {},
        });
      }
    },
  },
);

export default () => {
  const route = useRoute();
  search_config.model = reactive({ ...search_config.model });
  search_config.model.search_text = route.query.q;
  search_config.model.ids = Number(route.query.ids);
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_insurance_list,
    addData: create_insurance,
    updateData: update_insurance,
    removeData: delete_insurance,
    recoverData: recover_insurance,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
