import { query_receipt_list } from '@/api/receipt-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ADVISER_KEY, STORAGE_CURRENT_COMPANY, STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/receipt/receipt-receipt-config';
import { getDate, getMonth } from '@/utils/function';
import { notification } from 'ant-design-vue';
import { reactive } from 'vue';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const current_company = ls.get(STORAGE_CURRENT_COMPANY); // 当前cc公司
const adviser = ls.get(STORAGE_CURRENT_ADVISER_KEY);

const { t } = useI18n();
import { useRoute } from 'vue-router';
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'rangepicker',
      name: 'receipt_date',
      label: '开票日期',
      label_i18n: '开票日期',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      format: 'YYYY-MM-DD',
      placeholder: '请选择开票日期',
      placeholder_i18n: '请选择开票日期',
    },
    {
      type: 'monthpicker',
      name: 'service_month',
      label: '服务月份',
      label_i18n: '服务月份',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      format: 'YYYY-MM',
      placeholder: '请选择服务月份',
      placeholder_i18n: '请选择服务月份',
    },
    {
      type: 'rangepicker',
      name: 'payment_date',
      label: '付款日期',
      label_i18n: '付款日期',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      format: 'YYYY-MM-DD',
      placeholder: '请选择付款日期',
      placeholder_i18n: '请选择付款日期',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    receipt_date: null,
    service_month: null,
    payment_date: null,
    adviser_id: adviser.adviser_id,
    email_record__status: [1, 3],
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: [6, 6],
  },
  fields: [],
  rules: {
    receipt_date: [{ required: true, message: '请选择开票日期' }],
    receipt_type_id: [{ required: true, message: '请选择票据种类' }],
  },
  model: reactive({
    org_id: current_org?.id,
    receipt_type_id: null,
    receipt_contract_id: null,
    customer_id: null,
    receipt_kind: null,
    receipt_date: null,
    service_month: null,
    payment_date: null,
    amount: null,
    currency_id: null,
    data: null,
  }),
};
const edit_model_config_options = {
  receipt_kind: [
    { value: 0, name: '收取发票' },
    { value: 1, name: '开具发票' },
  ],
};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    receipt_type_id: null,
    receipt_contract_id: null,
    customer_id: null,
    receipt_kind: null,
    receipt_date: null,
    service_month: null,
    payment_date: null,
    amount: null,
    currency_id: null,
    data: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('发票清单'),
  addModalTitle: '新建发票',
  updateTitle: '修改发票',
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: false, // 是否有【删除】按钮
  is_recover: false, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    receipt_id: 'id',
  },
  delete: {
    receipt_id: 'id',
  },
  recover: {
    receipt_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    receipt_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    fixed: 'left',
    width: 50,
  },
  {
    title: t('ID'),
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('合同起始日期'),
    width: 105,
    dataIndex: 'receipt_contract__start_date',
    checked: true,
    fixed: 'left',
  },
  {
    title: t('合同结束日期'),
    width: 105,
    dataIndex: 'receipt_contract__end_date',
    checked: true,
    fixed: 'left',
  },
  {
    title: t('CC公司'),
    width: 300,
    dataIndex: 'receipt_contract__cc_company__name',
    checked: true,
  },
  {
    title: t('票据种类'),
    width: 75,
    dataIndex: 'receipt_type__name',
    checked: true,
  },
  // {
  //   title: t('发票类型'),
  //   width: 90,
  //   dataIndex: 'receipt_kind',
  //   checked: true,
  //   content: (record: any) => {
  //     return record.receipt_kind == 0 ? t('收取发票') : record.receipt_kind == 1 ? t('开具发票') : '';
  //   },
  //   filterMultiple: false,
  //   filters: [
  //     { text: t('全部'), value: '' },
  //     { text: t('收取发票'), value: '0' },
  //     { text: t('开具发票'), value: '1' },
  //   ],
  // },
  // {
  //   title: t('发票数据'),
  //   width: 150,
  //   dataIndex: 'data',
  //   checked: true,
  //   cellComponent: 'attributetable',
  //   content: (record: any, text: any) => {
  //     let list: any = [];
  //     if (record.data) {
  //       Object.keys(record.data).map((key: string) => {
  //         list.push({
  //           name: key,
  //           value: record.data[key],
  //         });
  //       });
  //     }
  //     return list;
  //   },
  // },
  {
    title: t('开票日期'),
    width: 95,
    dataIndex: 'receipt_date',
    checked: true,
    content: (record: any) => {
      return getDate(record.receipt_date);
    },
  },
  {
    title: t('付款日期'),
    width: 95,
    dataIndex: 'payment_date',
    checked: true,
    content: (record: any) => {
      return getDate(record.payment_date);
    },
  },
  {
    title: t('服务月份'),
    width: 80,
    dataIndex: 'service_month',
    checked: true,
    content: (record: any) => {
      return getMonth(record.service_month);
    },
  },
  {
    title: t('总金额'),
    width: 90,
    dataIndex: 'amount',
    checked: true,
  },
  {
    title: t('币种'),
    width: 70,
    dataIndex: 'currency__currency',
    checked: true,
  },
  {
    title: t('付款状态'),
    width: 90,
    dataIndex: 'payment_status',
    checked: true,
    content: (record: any) => {
      return record.payment_status == 0 ? t('未付款') : record.payment_status == 1 ? t('已付款') : '';
    },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('未付款'), value: '0' },
      { text: t('已付款'), value: '1' },
    ],
  },
  {
    title: t('附件'),
    width: 200,
    dataIndex: 'appendix_json',
    checked: true,
    cellComponent: 'clicklist',
    cb: (record: any) => {
      if (record && record.file_url) {
        const url: string = 'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.file_url);
        window.open(url, '_blank');
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
  },
  // {
  //   title: t('增扣项'),
  //   width: 80,
  //   dataIndex: 'extra__custom_field_name',
  //   checked: true,
  // },
  // {
  //   title: t('增扣项值'),
  //   width: 80,
  //   dataIndex: 'extra__custom_field_value',
  //   checked: true,
  // },
  // {
  //   title: t('邮件发送记录'),
  //   width: 150,
  //   dataIndex: 'email_record__log',
  //   checked: true,
  // },
  // {
  //   title: t('邮件发送状态'),
  //   width: 105,
  //   dataIndex: 'email_record__status',
  //   checked: true,
  //   content: (record: any) => {
  //     if (record.email_record__status == 1) {
  //       return t('发送成功');
  //     } else if (record.email_record__status == 2) {
  //       return t('发送失败');
  //     } else if (record.email_record__status == 0) {
  //       return t('已发送');
  //     }
  //   },
  // },
  // {
  //   title: t('邮件发送时间'),
  //   width: 120,
  //   dataIndex: 'email_record__update_time',
  //   checked: true,
  //   content: (record: any) => {
  //     return getDateTime(record.email_record__update_time);
  //   },
  // },
  // {
  //   title: t('创建时间'),
  //   width: 150,
  //   dataIndex: 'create_time',
  //   checked: true,
  //   content: (record: any) => {
  //     return getDateTime(record.create_time);
  //   },
  // },
  // {
  //   title: t('修改时间'),
  //   width: 150,
  //   dataIndex: 'update_time',
  //   checked: true,
  //   content: (record: any) => {
  //     return getDateTime(record.update_time);
  //   },
  // },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push();

export default () => {
  const route = useRoute();
  search_config.model = reactive({ ...search_config.model });
  search_config.model.cc_company_id = route.query.cc_company_id ? route.query.cc_company_id : current_company?.id;
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_receipt_list,
    addData: null,
    updateData: null,
    removeData: null,
    recoverData: null,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
