import { create_payslip, delete_payslip, query_payslip_list, recover_payslip, update_payslip } from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ADVISER_KEY, STORAGE_CURRENT_COMPANY, STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/cerp/cerp-payslip-config';

import { reactive } from 'vue';
import { notification } from 'ant-design-vue';
import axios from 'axios';
import router from '@/router';
import { getMonth } from '@/utils/function';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const None = null;
const True = true;
const False = false;
const { t } = useI18n();
const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const adviser = ls.get(STORAGE_CURRENT_ADVISER_KEY);
const current_company = ls.get(STORAGE_CURRENT_COMPANY); // 当前组织org

const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    adviser_id: adviser.adviser_id,
    cc_company_id: current_company?.id,
    invoice_id: null,
    payment_method_id: null,
    email_record__status: 1,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [],
  rules: {
    custom_4: [{ required: true, message: '请填输入自定义4' }],
    remark: [{ required: true, message: '请填输入备注' }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    date: null,
    invoice_id: null,
    custom_4: null,
    referral_fees: null,
    remark: null,
    cc_bank_account_id: null,
    bank_charge_cost: null,
    transaction_id: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [],
  rules: {},
  model: reactive({}),
};
const update_model_config_options = {};

const titleList = {
  title: t( 'router.title.cerp-payslip'),
  addModalTitle: '新建工资单',
  updateTitle: '修改工资单',
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: false, // 是否有【删除】按钮
  is_recover: false, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    payslip_id: 'id',
  },
  delete: {
    payslip_id: 'id',
  },
  recover: {
    payslip_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    payslip_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.cerp.payslip.colums.column.title.index'),
    dataIndex: 'index',
    width: 50,
    fixed: 'left',
  },
  {
    title: t('page.cerp.payslip.colums.column.title.service_month'),
    width: 150,
    dataIndex: 'invoice__service_month',
    checked: true,
    content: (record: any) => {
      return getMonth(record.create_time);
    },
  },
  {
    title: t('page.cerp.payslip.colums.column.title.payslip_no'),
    minWidth: 200,
    dataIndex: 'payslip_no',
    checked: true,
  },
  {
    title: t('page.cerp.contract.colums.column.title.cc_company__name'),
    minWidth: 200,
    dataIndex: 'invoice__contract__cc_company__name',
    checked: true,
  },
  {
    title: t('page.cerp.payslip.colums.column.title.pdf'),
    width: 300,
    dataIndex: 'files_json',
    checked: true,
    cellComponent: 'click',
    content: (record: any) => {
      if (record.files_json) {
        return record.files_json?.name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      if (record.files_json && record.files_json.file_url) {
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + record.files_json.file_url;
        window.open(url, '_blank');
      } else {
        notification.error({
          message:  t('page.Induction.message.reminder'),
          description: t('page.Induction.openUrl.description'),
        });
      }
    },
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push(
  {
    type: 'custom',
    slotName: 'is_valid',
    field_true: '有效',
    field_false: '无效',
  },
  {
    type: 'custom',
    slotName: 'payment_status',
    field_0: '未收到款项',
    field_1: '收到款项',
    field_2: '已付款',
    field_3: '垫付',
  },
  {
    type: 'custom',
    slotName: 'pdf_status',
    field_0: '未开始',
    field_1: '正在生成',
    field_2: '失败',
    field_3: '成功',
  },
  {
    type: 'click',
    slotName: 'to_do_num',
    cb: (record: any) => {
      router.push({
        path: '/adviser-manage/cerp-todoevent',
        query: {adviser_id: record.adviser_id, t: (new Date()).getTime()}
      });
    },
  },
  {
    type: 'custom',
    slotName: 'pay_together_status',
    field_0: '无',
    field_1: '同下次薪资合并',
    field_2: '暂且不付',
    field_3: '合并所有历史付款',
  },
  {
    type: 'custom',
    slotName: 'invoice_type',
    field_1: '工资',
    field_2: '报销',
    field_3: 'credit note',
    field_4: '加班',
    field_5: 'CC报销',
  },
  {
    type: 'custom',
    slotName: 'status',
    field_1: '新创建',
    field_2: '已发送',
    field_3: '已确认',
    field_4: '生成PDF',
  },
  {
    type: 'color',
    slotName: 'email_record__status',
    cb: (record: any) => {
      if (record.email_record__status == 0 || record.email_record__status == 1) {
        return 'font-weight: bolder';
      } else if (record.email_record__status == 2) {
        return 'color: #FF0000;font-weight: bolder';
      }
    },
    content: (record: any) => {
      if (record.email_record__status == 1) {
        return '发送成功';
      } else if (record.email_record__status == 2) {
        return '发送失败';
      } else if (record.email_record__status == 0) {
        return '已发送';
      }
    },
  },
  {
    type: 'click1',
    slotName: 'invoice__remittance__remittance_set__remittance_name',
    content: (record: any) => {
      if (record.invoice__remittance__remittance_set__remittance_name) {
        return record.invoice__remittance__remittance_set__remittance_name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      router.push({path: '/adviser-manage/cerp-remittanceset', query: {ids: [parseInt(record.invoice__remittance__remittance_set_id)], t: (new Date()).getTime()}});
    },
  },
  {
    type: 'click',
    slotName: 'transaction__name',
    content: (record: any) => {
      if (record.transaction__name) {
        return record.transaction__name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      router.push({ path: '/adviser-manage/cerp-banktransaction', query: { id: record.transaction_id, t: (new Date()).getTime() } });
    },
  },
  {
    type: 'click',
    slotName: 'files_json',
    content: (record: any) => {
      if (record.files_json) {
        return record.files_json?.name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      if (record.files_json && record.files_json.file_url) {
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + record.files_json.file_url;
        window.open(url, '_blank');
        // axios.get('/' + record.files_json.file_url,{
        //   responseType: 'blob' }).then((res: any) => {
        //   var blob = res.data;
        //   // FileReader主要用于将文件内容读入内存
        //   var reader = new FileReader();
        //   reader.readAsDataURL(blob);
        //   // onload当读取操作成功完成时调用
        //   reader.onload = (e: any) => {
        //     var a = document.createElement('a');
        //     // 获取文件名fileName
        //     var fileName = res.config["url"].split("/");
        //     fileName = fileName[fileName.length - 1];
        //     // fileName = fileName.replace(/"/g, "");
        //     a.download = fileName;
        //     a.href = e.target.result;
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        //   }
        // })
      } else {
        notification.error({
          message: '提示',
          description: '无对应附件',
        });
      }
    },
  },
  {
    type: 'click1',
    slotName: 'invoice__invoice_no',
    content: (record: any) => {
      return record.invoice__invoice_no;
    },
    cb: (record: any) => {
      router.push({
        path: '/adviser-manage/cerp-invoice',
        query: {id: record.invoice_id, t: (new Date()).getTime()}
      });
    },
  },
  {
    type: 'click',
    slotName: 'excel_files_json',
    content: (record: any) => {
      if (record.excel_files_json) {
        return record.excel_files_json?.name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      if (record.excel_files_json && record.excel_files_json.file_url) {
        // const url: string =
        //   'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + record.excel_files_json.file_url;
        // window.open(url, '_blank');
        axios.get('/' + record.excel_files_json.file_url,{
          responseType: 'blob' }).then((res: any) => {
          var blob = res.data;
          // FileReader主要用于将文件内容读入内存
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          // onload当读取操作成功完成时调用
          reader.onload = (e: any) => {
            var a = document.createElement('a');
            // 获取文件名fileName
            var fileName = res.config["url"].split("/");
            fileName = fileName[fileName.length - 1];
            // fileName = fileName.replace(/"/g, "");
            a.download = fileName;
            a.href = e.target.result;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        })
      } else {
        notification.error({
          message: '提示',
          description: '无对应附件',
        });
      }
    },
  },
);

export default () => {
  const route = useRoute();
  search_config.model = reactive({ ...search_config.model });
  search_config.model.cc_company_id = route.query.cc_company_id ? route.query.cc_company_id : current_company?.id;
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_payslip_list,
    addData: create_payslip,
    updateData: update_payslip,
    removeData: delete_payslip,
    recoverData: recover_payslip,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
