import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { useRouter } from 'vue-router';

const router = useRouter();

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
export const event_obj = mitt();
export const btn_list = [];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list = [];
